import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = ({aboutRef}) => {

  const { about } = useContext(PortfolioContext);
  const { img, paragraphTwo, resume } = about;
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about" ref={aboutRef}>
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  Hey! I'm front-end web developer from Wrocław, specializes in applications based on the React library.
                  I have passion for creating <span>intuitive</span>, <span>fast</span> and <span>dynamic</span> user experiences.
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo}
                </p>
                <p className="about-wrapper__info-text">
                  If you are looking for a <span>hard-working</span> and <span>ambitious</span> person, just contact me!
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                      download={`Krzysztof_Jakubiak_CV`}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
        {/*<Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">*/}
        {/*<div className="technologies">*/}
        {/*  <h3>Technologies</h3>*/}
        {/*  <p>These are technologies, tools and concepts I use in my projects. I'm currently learning TypeScript and GraphQL.</p>*/}
        {/*  <div className="technologies-lists">*/}
        {/*    <ul>*/}
        {/*      <li>HTML</li>*/}
        {/*      <li>CSS</li>*/}
        {/*      <li>Sass</li>*/}
        {/*      <li>RWD</li>*/}
        {/*      <li>BEM</li>*/}
        {/*      <li>JavaScript ES6+</li>*/}
        {/*      <li>React & Hooks</li>*/}
        {/*      <li>Redux</li>*/}
        {/*      <li>Firebase</li>*/}
        {/*    </ul>*/}
        {/*    <ul>*/}
        {/*      <li>Styled Components</li>*/}
        {/*      <li>Bootstrap</li>*/}
        {/*      <li>Formik & Yup</li>*/}
        {/*      <li>React Hook Form</li>*/}
        {/*      <li>Git</li>*/}
        {/*      <li>React Router</li>*/}
        {/*      <li>Axios</li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*</Fade>*/}
      </Container>
    </section>
  );
};

export default About;
