import React, { useState, useEffect, useRef } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  const [activeLink, setActiveLink] = useState('hero');
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  const handleIntersection = ([entry]) => {
    if (entry.target.id !== activeLink && entry.isIntersecting) {
      setActiveLink(entry.target.id);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      handleIntersection,
    );
    observer.observe(heroRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let config;
    if (window.innerWidth > 769) {
      config = {threshold: 0.5};
    } else {
      config = {threshold: 0.5};
    }
    const observer = new IntersectionObserver(
      handleIntersection,
      config
    );
    observer.observe(aboutRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let config;
    if (window.innerWidth > 769) {
      config = {threshold: 0.4};
    } else {
      config = {}
    }
    const observer = new IntersectionObserver(
      handleIntersection,
      config
    );
    observer.observe(projectsRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let config;
    if (window.innerWidth > 769) {
      config = {threshold: 0.4};
    } else {
      config = {}
    }
    const observer = new IntersectionObserver(
      handleIntersection,
      config
    );
    observer.observe(contactRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
      <Hero heroRef={heroRef} activeLink={activeLink} />
      <About aboutRef={aboutRef} />
      <Projects projectsRef={projectsRef} />
      <Contact contactRef={contactRef} />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
