import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = ({heroRef, activeLink}) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;
  const [isActive, setIsActive] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset > sticky + 50) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });

    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  const handleClick = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <section id="hero" className="jumbotron" ref={heroRef}>
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title}
            <span className="text-color-main">{name}</span>
            <br />
            {subtitle}
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={800}>
                {cta}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
      {isDesktop ? (
        <nav id="myHeader">
          <ul>
            <Link to="hero" smooth duration={1000} onClick={handleClick} className={activeLink === 'hero' ? 'active-section' : ''}>
              Home
            </Link>
            <Link to="about" smooth duration={1000} onClick={handleClick} className={activeLink === 'about' ? 'active-section' : ''}>
              About
            </Link>
            <Link to="projects" smooth duration={1000} onClick={handleClick} className={activeLink === 'projects' ? 'active-section' : ''}>
              Portfolio
            </Link>
            <Link to="contact" smooth duration={1000} onClick={handleClick} className={activeLink === 'contact' ? 'active-section' : ''}>
              Contact
            </Link>
          </ul>
        </nav>
      ) : (
        <nav id="myHeader">
          <button className={`hamburger hamburger--slider ${isActive ? 'is-active' : ''}`} type="button" onClick={handleClick}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <ul className={isActive ? 'visible' : ''}>
            <Link to="hero" smooth duration={1000} onClick={handleClick} className={activeLink === 'hero' ? 'active-section' : ''}>
              Home
            </Link>
            <Link to="about" smooth duration={1000} onClick={handleClick} className={activeLink === 'about' ? 'active-section' : ''}>
              About
            </Link>
            <Link to="projects" smooth duration={1000} onClick={handleClick} className={activeLink === 'projects' ? 'active-section' : ''}>
              Portfolio
            </Link>
            <Link to="contact" smooth duration={1000} onClick={handleClick} className={activeLink === 'contact' ? 'active-section' : ''}>
              Contact
            </Link>
          </ul>
        </nav>
      )}
    </section>
  );
};

export default Header;
